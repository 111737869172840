<template>
  <div class="container">
    <Breadcrumb>
      <template slot="restPage">
        <el-breadcrumb-item>
          {{ $t("greet.myTests") }}
        </el-breadcrumb-item>
      </template>
    </Breadcrumb>
    <Heading :content="$t('greet.myTests')"></Heading>
    <hr />
    <div>
      <el-form label-width="80px">
        <!-- <el-form-item label="Class">
          <el-select
            v-model="classIndex"
            placeholder="Select Classes"
            filterable
            class="select-test"
            @change="selectCourse"
          >
            <el-option
              v-for="(test, index) in classes"
              :key="test.id"
              :label="test.student_class_title"
              :value="index"
            ></el-option>
          </el-select>
        </el-form-item> -->
        <el-form-item :label="$t('grading.class')">
          <el-select
            v-model="classIndex"
            placeholder="Select Class"
            filterable
            class="select-test"
            @change="selectCourse"
          >
            <el-option
              v-for="(test, index) in studentClasses"
              :key="index"
              :value="index"
              :label="
                index == 'Others'
                  ? ` * ${$t('grading.n/aIndependentMockTest')}`
                  : index
              "
            >
              {{
                index == "Others"
                  ? ` * ${$t("grading.n/aIndependentMockTest")}`
                  : index
              }}
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="Tests">
          <el-select
            v-model="testIndex"
            placeholder="Select Test"
            filterable
            class="select-test"
            @change="selectTest"
          >
            <!-- TODO: 20230209：不要 -->
            <!-- <el-option label="The SAT® Practice Test #1" :value="-99">
            </el-option> -->
            <el-option-group
              v-for="group in tests"
              :key="group.label"
              :label="group.label"
            >
              <el-option
                v-for="(test, index) in group.options"
                :key="index"
                :label="
                  test.which_week
                    ? `Test${test.which_week}: ${test.exam_title}`
                    : `${test.exam_title}`
                "
                :value="test.user_exam_id"
                v-show="test.user_exam_id > 0 && test.exam_title !== null"
              ></el-option>
            </el-option-group>
          </el-select>
        </el-form-item>
        <template v-if="testIndex > -1">
          <template
            v-if="
              form.type &&
                form.type !== 'Toefl' &&
                form.type !== 'Compose Practice'
            "
          >
            <el-form-item label="考卷PDF">
              <el-checkbox v-model="getTest">获取考卷PDF</el-checkbox>
            </el-form-item>
            <div class="text-center" style="margin-bottom: 20px">
              <el-button
                type="success"
                @click="startClassGrading"
                :disabled="form.user_exam_id === ''"
              >
                Next
              </el-button>
            </div>
          </template>
          <template v-else>
            <div class="text-center" style="margin-bottom: 20px">
              <el-button type="success" @click="getTeacherClassExams">
                Next
              </el-button>
            </div>
          </template>
        </template>
        <template v-if="testIndex === -99">
          <div class="text-center" style="margin-bottom: 20px">
            <a
              :href="
                `https://sat.ivy-way.com/test/composePractices/12?token=${token}`
              "
            >
              <el-button type="success">
                Next
              </el-button>
            </a>
          </div>
        </template>
      </el-form>
    </div>
  </div>
</template>

<script>
/*eslint-disable no-unused-vars */
import { mapState, mapGetters, mapActions } from "vuex";
/*eslint-enable */
import _ from "lodash";
import Breadcrumb from "@/components/Breadcrumb";
import Tests from "@/apis/tests.js";

export default {
  metaInfo() {
    return {
      title: "Get Tests - Ivy-Way Academy"
    };
  },

  components: {
    Breadcrumb
  },

  mixins: [],

  props: [],
  data() {
    return {
      classes: [],
      classIndex: null,
      getTest: true,
      tests: [],
      testIndex: null,
      form: {
        exam_id: null,
        user_exam_id: null,
        type: null
      },
      studentClasses: []
    };
  },
  computed: {
    ...mapState("user", ["profile", "token"])
  },
  watch: {},

  mounted() {
    this.getStudentTests();
    // this.getStudentsExams();
  },

  methods: {
    async getStudentTests() {
      // const res = await TOEFL.getStudentExams(this.profile.id);
      // this.studentTests = res.user.user_exams;
      const res = await Tests.getClassesExams();
      let classes = {};
      for (let i in res) {
        if (i === "user_exams") {
          classes["Others"] = res[i];
        } else {
          if (res[i].length > 0) {
            res[i].forEach(element => {
              classes[element.student_class_title] =
                element.student_class_ticket_exams;
            });
          }
        }
      }
      this.studentClasses = classes;
    },
    async getStudentsExams() {
      const res = await Tests.getStudentsExams();
      this.classes = res.student_class_tickets;
    },
    startClassGrading() {
      if (this.getTest) {
        this.$router.push({
          path: "/tests/start",
          query: {
            user_exam_id: this.form.user_exam_id
          }
        });
      } else {
        this.$router.push({
          path: "/tests/timer",
          query: this.form
        });
      }
    },
    async getTeacherClassExams() {
      const res = await Tests.getTeacherClassExams(this.form.exam_id);
      if (res.toefl_exam) {
        this.routerPush({
          name: "SetTest",
          query: {
            title: res.toefl_exam.title,
            testType: "mock",
            version: res.toefl_exam.type
          }
        });
      }
      if (res.compose_practice_id) {
        this.$router.push({
          name: "FullPractice",
          params: {
            fullPracticeId: res.compose_practice_id
          }
        });
      }
    },
    selectCourse() {
      let tests = this.studentClasses[this.classIndex];
      tests = _.groupBy(tests, "exam_category_title");
      let testArr = [];
      for (const key in tests) {
        testArr.push({
          label: key,
          options: tests[key]
        });
      }
      this.tests = testArr;
      this.form = {
        exam_id: null,
        user_exam_id: null,
        type: null
      };
      this.testIndex = null;
    },
    selectTest() {
      if (this.testIndex > -98) {
        let examObj = null;
        this.tests.forEach(test => {
          test.options.forEach(exam => {
            if (exam.user_exam_id === this.testIndex) {
              examObj = exam;
            }
          });
        });
        this.form.user_exam_id = examObj.user_exam_id;
        this.form.type = examObj.exam_category_title;
        this.form.exam_id = examObj.exam_id;
      }
    }
  }
};
</script>

<style scoped>
.select-test {
  width: 100%;
}
</style>
